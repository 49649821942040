import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { Helmet } from 'react-helmet'
import Iframe from 'react-iframe'

const Dpa = () => {
  return (
    <Layout>
      <SEO 
        title="EU DPA Addendum" 
        description="Our model EU DPA Addendum is ready to sign. Great if you need subscription billing software that lets you take recurring payments and comply with GDPR." 
        url="https://www.billsby.com/privacy/dpa"
      />

      <div className="default-template">
        <div className="section-tab">
          <div className="container">
            <ul className="tab-wrapper">
              <li className="tab-item"><Link to="/terms">Terms and conditions</Link></li>
              <li className="tab-item"><Link to="/privacy">Privacy policy</Link></li>
              <li className="is-active tab-item"><Link to="/privacy/dpa">EU Data Processing Addendum</Link></li>
              <li className="tab-item"><Link to="/privacy/cookie-policy">Cookie policy</Link></li>
              <li className="tab-item"><Link to="/privacy/sub-processors">Sub-processors</Link></li>
              <li className="tab-item"><Link to="/terms/acceptable-use">Acceptable use policy</Link></li>
            </ul>

            <p className="date">EFFECTIVE 1ST AUGUST 2019</p>
            <h1 className="section-heading">EU Data Processing Addendum</h1>
          </div>
        </div>

        <div className="container default-content">
          <p>In the course of providing our service, Billsby may process personal data on your behalf. In order to outline specifics of how we will perform this processing and what our obligations are as well as the obligations of our users/customers we’ve developed a Data Processing Agreement (DPA) that we enter into free of charge with anyone that uses our service and requests it.</p>
          <p>This document forms part of a contract of service with Billsby (as the <strong>data processor</strong>) and our users/customers (as the <strong>controllers</strong>). The DPA reflects the parties’ agreement with regard to the processing of personal data performed using our service.</p>
          <p> As a <strong>controller</strong>, in order to sign this agreement, you must review and digitally sign a copy of the Data Processing Agreement. Once you sign the agreement, you will immediately receive a fully executed downloadable copy via email.</p>
          <p>Upon Billsby’s receipt of the validly completed and digitally signed <strong>agreement</strong>, this <strong>agreement</strong> shall be in full force and effect.</p>

          <Iframe  src='https://sign.signable.app/#/widget/5d2eXzQFVs' frameborder='0' style='border:0;width:100%;height:100vh;' scrolling='yes' />
        </div>
      </div>
    </Layout>
  )
}

export default Dpa